<template>
  <div align="left" style="flex-grow: 1; display: flex; flex-direction: column">
    <div class="hr1" style="margin: 1rem 0 0.6rem"></div>
    <div>
      <h4 style="margin: 0.3rem 0 0.6rem">
        {{ onlyRead ? "Cod CPV" : "Codul CPV selectat" }}
      </h4>
    </div>
    <div style="overflow: auto">
      <table class="custom_docTb notFixedTableForMobile">
        <thead id="CTHDWAA">
          <th style="width: 5rem">Nr.</th>
          <th colspan="2">Diviziunea</th>
          <th>Grupul</th>
          <th>Clasa</th>
          <th>Categoria</th>
          <th>Denumire</th>
        </thead>
        <tbody>
          <td v-if="!value" colspan="7">
            <h4 align="center">Nu a fost selectat nimic</h4>
          </td>
          <tr v-else>
            <td style="text-align: center">1</td>
            <td colspan="2">{{ value.division }}</td>
            <td>{{ value.group }}</td>
            <td>{{ value.class }}</td>
            <td>{{ value.category }}</td>
            <td>{{ `(${value.cpvCode}) ${value.name}` }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="!onlyRead">
      <div
        class="hr1"
        style="margin-top: 1rem; margin-bottom: 1rem; align-items: flex-end"
      ></div>
      <div
        class="alert alert-warning mobileClampFontSize"
        role="alert"
        style="margin: 0 0 1rem 0"
      >
        Dacă identificați mai multe coduri CPV care reflectă nevoia dorită, vă
        rugăm să selectați codul CPV cu cele mai puține zerouri înainte de
        cratimă "-". <br />
        Ex. Dacă avem de ales între 39225720-8 Borcane și 39225700-2 Sticle.
        borcane și flacoane, codul CPV corect este 39225720-8 pentru că are mai
        puține zerouri înainte de cratimă"-".
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        "
      >
        <h4 style="margin: 0rem 0 0.6rem">Rezultatele căutării</h4>
        <select @change="viewRowsManager" style="padding: 0.25rem 0.5rem">
          <option value="0">Primele 10</option>
          <option value="1">Primele 20</option>
          <option value="2">Primele 50</option>
        </select>
      </div>
      <div
        ref="table"
        class="CTTableParent"
        style="flex-grow: 1; min-height: 200px; margin-bottom: 1rem"
      >
        <div
          ref="Table2"
          class="CTTable"
          style="height: 100%"
          :style="`max-height:${tableHeight}px;`"
        >
          <table
            class="custom_docTb notFixedTableForMobile"
            :class="{ CTLoading: !searchState && searched.length > 2 }"
            style="height: 100%"
          >
            <thead>
              <th style="width: 5rem">Nr.</th>
              <th colspan="2">Diviziunea</th>
              <th>Grupul</th>
              <th>Clasa</th>
              <th>Categoria</th>
              <th>Denumire</th>
              <th
                v-if="searchState === true"
                style="width: 3rem; text-align: center"
              ></th>
            </thead>
            <tbody>
              <td
                v-if="searched.length < 3"
                style="border-bottom: 0"
                :colspan="searchingTableCols"
              >
                <h4 style="color: #df302d; cursor: default" align="center">
                  <i class="fas fa-exclamation-triangle"></i> Căutarea trebuie
                  să conțină minim 3 caractere
                </h4>
              </td>
              <template v-else>
                <td
                  v-if="!searchState"
                  style="border-bottom: 0"
                  :colspan="searchingTableCols"
                >
                  <div
                    align="center"
                    style="font-size: 4.5rem; margin-top: 0.25rem"
                  >
                    <i class="fas fa-sync fa-spin"></i>
                  </div>
                </td>
                <td
                  v-else-if="searchState === 1"
                  style="border-bottom: 0"
                  :colspan="searchingTableCols"
                >
                  <h4 align="center">Nimic nu a fost găsit</h4>
                </td>
                <tr v-else v-for="(row, i) in cpvData" :key="row.cpvCode">
                  <td style="text-align: center">
                    {{ i + 1 + addPagesCount }}
                  </td>
                  <td colspan="2" v-html="highlightCell(row.division)"></td>
                  <td v-html="highlightCell(row.group)"></td>
                  <td v-html="highlightCell(row.class)"></td>
                  <td v-html="highlightCell(row.category)"></td>
                  <td
                    v-html="
                      '(' +
                        highlightCell(row.cpvCode) +
                        ') ' +
                        highlightCell(row.name)
                    "
                  ></td>
                  <td>
                    <i
                      @click="selectCpv(row.id)"
                      :class="
                        `fas fa-${row._selected ? 'minus' : 'plus'}-circle`
                      "
                      style="cursor: pointer; color: #39f"
                    ></i>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="!isNaN(cpvCount)"
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div>
          <b>Nr. total de rezultate: {{ cpvCount }}</b>
        </div>
        <div style="display: flex">
          <div id="Pages" style="margin-right: 0.5rem">
            <template v-for="page in pages">
              <a
                :key="page"
                @click.prevent="selectPage(page)"
                href="#"
                class="PageBtn"
                :class="{
                  PageActive: page === currPage,
                  PageDisabled: !parseInt(page),
                }"
              >
                <span>{{ page }}</span>
              </a>
            </template>
          </div>
          <a href="#" class="redLink" @click.prevent="clearSearch()"
            >Șterge <i class="fas fa-times"></i
          ></a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CPV_CATEGORY_PAGINATED, PRODUCTS_PAGINATED } from "@/api.js";
const viewRowsMap = {
  0: 10,
  1: 20,
  2: 50,
};
const searchTimeoutCount = 800;
let searchTimeout = null;
const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
  strategy: "scroll",
});
const getCurrentTime = () => new Date().getTime();
const pagination = (num, limit, range) => {
  range = range || 3;
  var arr = [];
  for (var i = 1; i <= limit; i++) {
    if (
      i <= range ||
      (i > num - range / 2 && i < num + range / 2) ||
      i > limit - range
    ) {
      if (arr[arr.length - 1] && i != arr[arr.length - 1] + 1) arr.push("...");
      arr.push(i);
    }
  }
  return arr;
};

export default {
  props: {
    cpvType: {
      required: true,
      default: () => ({}),
    },
    onlyRead: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: String,
      required: true,
      default: "",
    },
    searchCount: {
      type: Number,
      required: false,
      default: 0,
    },
    dynamicSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {},
  },
  data() {
    return {
      searched: "",
      cpvData: [],
      selectedCpv: false,
      cpvCount: NaN,
      viewRows: 10,
      lastSearchedTime: 0,
      tableHeight: NaN,
      currPage: 1,
    };
  },
  computed: {
    sch() {
      return this.search.trim();
    },
    searchState() {
      if (!this.cpvData.length) return false;
      if (!this.cpvData[0]) return 1;
      return true;
    },
    highlightReg() {
      const districtsMap = [
        ["a", "ă", "â", "â"],
        ["t", "ț", "ţ"],
        ["s", "ș", "ş"],
        ["i", "î"],
      ];
      let inp = this.searched;
      districtsMap.forEach(
        (e) =>
          (inp = inp.replace(
            new RegExp(`[${e.join("")}]`, "gi"),
            `[${e.join("")}]`
          ))
      );

      return new RegExp(inp, "gi");
    },
    searchingTableCols() {
      return this.searchState === true ? 8 : 7;
    },
    pages() {
      return (
        pagination(
          this.currPage,
          Math.ceil(this.cpvCount / this.viewRows),
          4
        ) || []
      );
    },
    addPagesCount() {
      return (this.currPage - 1) * this.viewRows;
    },
  },
  watch: {
    selectedCpv(x) {
      this.cpvData.forEach((e) => (e._selected = e.id === x));

      if (x) {
        const find = this.cpvData.find((e) => e.id === x);
        if (find) {
          this.update({ ...find });
        }
      } else {
        this.update(null);
      }
    },
    cpvType() {
      this.clearSearch();
    },
    searchCount() {
      this.Search();
      this.currPage = 1;
    },
    search(x) {
      if (x?.length === 0 && this.searched) {
        this.clearSearch();
      }
    },
  },
  methods: {
    updateTableHeight() {
      window.requestAnimationFrame(() => {
        const x = +this.$refs["table"]?.offsetHeight;
        this.tableHeight = isNaN(x) ? 400 : Math.max(500, x);
      });
    },
    highlightCell(val) {
      return val?.replace(this.highlightReg, (e) => e.bold()) || "";
    },
    viewRowsManager(e) {
      this.viewRows = viewRowsMap[e?.target?.value] || 10;
      this.currPage = 1;
      this.Search(true);
    },
    selectPage(x) {
      x = parseInt(x);
      if (!x || x == this.currPage) return;
      this.currPage = x;
      this.Search(true);
    },
    selectCpv(id) {
      if (!Number.isInteger(id)) return;
      PRODUCTS_PAGINATED(1, 1, null, `eq=cpvCategoryId,${id}`)
        .then(async (data) => {
          if (data && data.data.recordsQuantity > 0) {
            this.$toastr.w("Acest produs a fost adaugat deja.");
          } else {
            this.selectedCpv = this.selectedCpv === id ? false : id;
          }
        })
        .catch((err) => {
          this.$toastr.e(err);
        });
    },
    Search(reSearch) {
      if (!reSearch) {
        this.searched = this.sch;
      } else if (!this.searched.trim()) {
        return;
      }
      const x = reSearch ? this.searched : this.sch;
      if (x.length < 3) {
        return;
      }
      this.cpvData.splice(0);

      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(
        () => {
          this.lastSearchedTime = getCurrentTime();
          ((y) => {
            const searchType = this.dynamicSearch
              ? "columnsOr"
              : "columnsOrStrict";

            CPV_CATEGORY_PAGINATED(
              this.currPage,
              this.viewRows,
              this.cpvType.id,
              [searchType, `cpvCode,name,division,group,class,category||${x}`]
            )
              .then((res) => {
                if (y !== this.searchCount) return;
                if (
                  res &&
                  this.checkHttpStatusCode(res.meta.HttpStatusCode) &&
                  Array.isArray(res.data?.result)
                ) {
                  this.cpvData = res.data.result.length
                    ? res.data.result.map((e) =>
                        Object.assign(e, {
                          _selected: e.id === this.selectedCpv,
                        })
                      )
                    : [false];
                  this.cpvCount = Number.isInteger(res.data.recordsQuantity)
                    ? res.data.recordsQuantity
                    : NaN;
                } else {
                  this.clearSearch();
                  this.$toastr.e("Încercați mai târziu");
                }
              })
              .catch((err) => {
                if (this.currPage !== 1) {
                  this.currPage = 1;
                  this.Search(true);
                } else this.clearSearch();
                this.$toastr.e(err);
              });
          })(this.searchCount);
        },
        getCurrentTime() - this.lastSearchedTime > searchTimeoutCount
          ? 0
          : searchTimeoutCount
      );
    },
    clearSearch() {
      this.$emit("clearCpvSearch");
      this.searched = "";
      this.cpvCount = NaN;
      this.cpvData = [];
      this.currPage = 1;
    },
    update(x) {
      this.$emit("input", x || null);
    },
  },
  beforeDestroy() {
    if (this.$refs["table"])
      erdUltraFast.removeListener(this.$refs["table"], this.updateTableHeight);
  },
  mounted() {
    const el = this.$refs["table"];
    if (el) {
      erdUltraFast.listenTo(el, this.updateTableHeight);
    }

    this.updateTableHeight();
  },
};
</script>

<style scoped>
table {
  transition: opacity 250ms;
}
#Pages .PageBtn {
  position: relative;
  margin-right: 0.25rem;
  overflow: hidden;
}
#Pages .PageBtn > * {
  position: relative;
  z-index: 1;
}
#Pages .PageBtn:after {
  position: absolute;
  display: block;
  content: "";
  top: 100%;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #c3c3c3;
  transition: top 200ms;
  z-index: 0;
}
#Pages .PageBtn:hover:after {
  top: 0;
}
#Pages .PageBtn:hover {
  color: #fff;
}
.PageBtn {
  display: inline-block;
  padding: 0 0.5rem;
}
.PageDisabled {
  color: #000;
  pointer-events: none;
}
.PageActive {
  background-color: #c3c3c3;
  color: #fff;
  pointer-events: none;
}
</style>
<style src="@/assets/CatalogSelect.css"></style>
